export const SliderOurCategory = [
    {
        image: '../assets/images/services/service1.jpg',
        title_ru: 'Моделирование',
        title_uz: 'Моделлаштириш',
        title_oz: 'Modellashtirish',
        title_en: 'Modeling',
        text_ru: 'Моделирование проекта',
        text_uz: 'Лойиҳани моделлаштириш',
        text_oz: 'Loyihani modellashtirish',
        text_en: 'Project Modeling',
    },
    {
        image: '../assets/images/services/service2.jpg',
        title_ru: 'Проектирование',
        title_uz: 'Дизайн',
        title_oz: 'Dizayn',
        title_en: 'Design',
        text_ru: 'Проектирование',
        text_uz: 'Дизайн',
        text_oz: 'Dizayn',
        text_en: 'Design',
    },
    {
        image: '../assets/images/services/service3.jpg',
        title_ru: 'Научно-исследовательских',
        title_uz: 'Тадқиқот',
        title_oz: 'Tadqiqot',
        title_en: 'Research',
        text_ru: 'Научно-исследовательских',
        text_uz: 'Тадқиқот',
        text_oz: 'Tadqiqot',
        text_en: 'Research',
    },
    {
        image: '../assets/images/services/service4.jpg',
        title_ru: 'Проектно-изыскательских работ',
        title_uz: 'Лойиҳалаш ва тадқиқот ишлари',
        title_oz: 'Loyihalash va tadqiqot ishlari',
        title_en: 'Design and survey works',
        text_ru: 'Проектно-изыскательских работ',
        text_uz: 'Лойиҳалаш ва тадқиқот ишлари',
        text_oz: 'Loyihalash va tadqiqot ishlari',
        text_en: 'Design and survey works',
    },
    {
        image: '../assets/images/services/service5.jpg',
        title_ru: 'Техническое обслуживание',
        title_uz: 'Техник хизмат кўрсатиш',
        title_oz: 'Texnik xizmat ko\'rsatish',
        title_en: 'Maintenance',
        text_ru: 'Техническое обслуживание здания',
        text_uz: 'Бинога техник хизмат кўрсатиш',
        text_oz: 'Binoga texnik xizmat ko\'rsatish',
        text_en: 'Building maintenance',
    },
]

export const sliderClient = [
    {
        image: "../assets/images/client1.png",
        fullName_ru: "Закари Фармер",
        fullName_uz: "Зачари Фермер",
        fullName_oz: "Zachari Fermer",
        fullName_en: "Zachary Farmer",
        position_ru: "разработчик",
        position_uz: "дастурчи",
        position_oz: "dasturchi",
        position_en: "developer",
        text_ru: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        text_uz: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        text_oz: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        text_en: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        signature: "../assets/images/signature.png"
    },
    {
        image: "../assets/images/client2.png",
        fullName_ru: "Долор Дейл",
        fullName_uz: "Долор Дейл",
        fullName_oz: "Dolor Deyl",
        fullName_en: "dolor dale",
        position_ru: "разработчик",
        position_uz: "дастурчи",
        position_oz: "dasturchi",
        position_en: "developer",
        text_ru: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        text_uz: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        text_oz: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        text_en: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini veniam, quis nostrud exercitation ullamco laboris",
        signature: "../assets/images/signature.png"
    }
]

